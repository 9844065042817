// Avoid `console` errors in browsers that lack a console.
(function() {
    var method;
    var noop = function () {};
    var methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }
}());

// Place any jQuery/helper plugins in here.

$(function(){

    // show/hide navigation toggle for mobile
    $('.navigation-toggle').click(function(e){
        $('.navigation').slideToggle();
        $('.fa', this).toggle();
        e.preventDefault();
    });

    // bind change event to select
    var url;
    $('#category_select').on('change', function () {
        if ( $(this).val() == 'all' ) {
            url = '/news';
        } else {
            url = '/news?category=' + $(this).val(); // get selected value
        }
        if (url) { // require a URL
            window.location = url; // redirect
        }
        return false;
    });

    // highlight active category on select
    var queryString = window.location.href.slice( window.location.href.indexOf('?') + 10);
    $("#category_select > option[value='" + queryString + "']").prop('selected', true);

    // Show fixed header on homepage scroll
    $(window).scroll(function() {
        var sticky = $('.home-header'),
        scroll = $(window).scrollTop();
        if (scroll >= 150) { 
            sticky.addClass('sticky'); }
        else { 
            sticky.removeClass('sticky');
        }
    });

    // Toggle description example on submission form
    $('.description-example svg').click(function(e) {
        e.preventDefault();
        $(this).siblings('.form-hint').slideToggle();
    });

});